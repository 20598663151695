import { User } from '@packages/types'

import { apiClient, createQuery } from 'common/api'

const useUserService = () => {
  return {
    fetchCurrent: createQuery(['current-user'], async () => {
      const { data } = await apiClient.get<User>(USER_INFOS_URL)
      return data
    }),
  }
}

export default useUserService
